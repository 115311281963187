export const logsExample = [
    "info: syncing channel updates for '1.69.0-aarch64-unknown-linux-gnu'",
    "info: latest update on 2023-04-20, rust version 1.69.0 (84c898d65 2023-04-16)",
    "info: downloading component 'cargo'",
    "info: downloading component 'rust-src'",
    "info: downloading component 'rust-std'",
    "info: downloading component 'rustc'",
    "info: installing component 'cargo'",
    "info: installing component 'rust-src'",
    "info: installing component 'rust-std'",
    "info: installing component 'rustc'",
    "  1.69.0-aarch64-unknown-linux-gnu installed - rustc 1.69.0 (84c898d65 2023-04-16)",
    "info: default toolchain set to '1.69.0-aarch64-unknown-linux-gnu'",
    "info: self-update is disabled for this build of rustup",
    "info: any updates to rustup will need to be fetched with your system package manager",
    "    Updating git repository `https://github.com/paritytech/cargo-contract`",
    "  Installing cargo-contract v2.2.1 (https://github.com/paritytech/cargo-contract?tag=v2.2.1#91b5a861)",
    "    Updating crates.io index",
    "Downloading crates ...",
    "  Downloaded unicode-normalization v0.1.22",
    "  Downloaded try-lock v0.2.4",
    "  Downloaded unicode-width v0.1.10",
    "  Downloaded toml v0.5.11",
    "  Downloaded subxt-codegen v0.28.0",
    "  Downloaded borsh-derive v0.10.3",
    "..."
]