import styles from "./ConnectToPatron.module.css"
import React from "react"

export function ConnectToPatron() {
    return (
        <div style={{ display: "inline-block" }}>
            <div
                className={styles.connectToPatron}
            >
                <p>Connect to Patron:</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 1.6C5.71134 1.6 4.66667 2.67452 4.66667 4C4.66667 5.32548 5.71134 6.4 7 6.4C8.28866 6.4 9.33333 5.32548 9.33333 4C9.33333 2.67452 8.28866 1.6 7 1.6ZM3.11111 4C3.11111 1.79086 4.85223 0 7 0C9.14777 0 10.8889 1.79086 10.8889 4C10.8889 6.20914 9.14777 8 7 8C4.85223 8 3.11111 6.20914 3.11111 4ZM1.78273 12.4644C1.56351 12.6643 1.55556 12.7796 1.55556 12.8C1.55556 12.8204 1.56351 12.9357 1.78273 13.1356C2.00106 13.3347 2.36135 13.5505 2.87332 13.748C3.89153 14.1407 5.35035 14.4 7 14.4C8.64965 14.4 10.1085 14.1407 11.1267 13.748C11.6387 13.5505 11.9989 13.3347 12.2173 13.1356C12.4365 12.9357 12.4444 12.8204 12.4444 12.8C12.4444 12.7796 12.4365 12.6643 12.2173 12.4644C11.9989 12.2653 11.6387 12.0495 11.1267 11.852C10.1085 11.4593 8.64965 11.2 7 11.2C5.35035 11.2 3.89153 11.4593 2.87332 11.852C2.36135 12.0495 2.00106 12.2653 1.78273 12.4644ZM2.32713 10.3539C3.56092 9.87799 5.21321 9.6 7 9.6C8.78679 9.6 10.4391 9.87799 11.6729 10.3539C12.2869 10.5907 12.8378 10.892 13.2492 11.2672C13.6597 11.6415 14 12.1577 14 12.8C14 13.4423 13.6597 13.9585 13.2492 14.3328C12.8378 14.708 12.2869 15.0093 11.6729 15.2461C10.4391 15.722 8.78679 16 7 16C5.21321 16 3.56092 15.722 2.32713 15.2461C1.7131 15.0093 1.16217 14.708 0.750768 14.3328C0.340272 13.9585 0 13.4423 0 12.8C0 12.1577 0.340272 11.6415 0.750768 11.2672C1.16217 10.892 1.7131 10.5907 2.32713 10.3539Z"
                        fill="#E42828"
                    />
                </svg>
            </div>
        </div>
    )
}
